import { Controller } from 'stimulus'

const statusClasses = {
  'cancelled': 'text-danger-modal',
  'failed': 'text-danger-modal',
  'imported': 'text-success-modal',
  'in_process': 'text-alert-modal',
  'pending': 'text-alert-modal',
};

export default class extends Controller {
  static targets = ['modal', 'quantity', 'quantityTitle', 'status', 'dateTitle', 'date', 'userTitle', 'user', 'fileLink', 'fileSpan']

  open(event) {
    event.preventDefault();

    const details = JSON.parse(event.currentTarget.dataset.details);

    this.showQuantityData(details);
    this.showStatus(details);
    this.showDate(details);
    this.showUser(details);
    this.showFile(details);
  }

  showQuantityData(details) {
    this.quantityTitleTarget.innerHTML  = details.quantity.title;
    this.quantityTarget.innerHTML  = details.quantity.content;
  }

  showStatus(details) {
    this.statusTarget.innerHTML  = details.status.content;
    this.statusTarget.classList.remove('text-success-modal', 'text-danger-modal', 'text-alert-modal');
    this.statusTarget.classList.add(statusClasses[details.status.code])
  }

  showDate(details) {
    this.dateTitleTarget.innerHTML  = details.date.title;
    this.dateTarget.innerHTML  = details.date.content;
  }

  showUser(details) {
    this.userTitleTarget.innerHTML = details.user.title;
    this.userTarget.innerHTML = details.user.content;
  }

  showFile(details) {
    this.fileSpanTarget;
    this.fileLinkTarget;
    this.fileLinkTarget.href = details.file.path;

    if (details.file.name != undefined) {
      this.fileSpanTarget.setAttribute("title", details.file.name);
      this.fileSpanTarget.dataset.toggle = "tooltip";
      this.fileSpanTarget.dataset.placement = "right";
      this.fileSpanTarget.dataset.trigger = "hover";

      this.fileLinkTarget.innerHTML = details.file.name;
    } else {
      this.fileLinkTarget.innerHTML = details.file.path;
    }
  }
}
