import BaseController from './base_controller'

export default class extends BaseController {
  static targets = ["hiddenInput"]
  
  connect() {
    // Store the bound function reference in a class property
    this.boundHandleFileSelected = this.handleFileSelected.bind(this)
    
    // Listen to the file selected event
    document.addEventListener('picture-input:file-selected', this.boundHandleFileSelected)
  }
  
  disconnect() {
    // Remove event listener using the same function reference
    document.removeEventListener('picture-input:file-selected', this.boundHandleFileSelected);
  }
  
  handleFileSelected(event) {
    const file = event.detail.file
    
    // Create a new DataTransfer to transfer the file to the hidden input
    const dataTransfer = new DataTransfer()
    dataTransfer.items.add(file)
    
    // Assign the file to the hidden input
    this.hiddenInputTarget.files = dataTransfer.files
    
    // Dispatch change event for Rails to detect the change
    const changeEvent = new Event('change', { bubbles: true })
    this.hiddenInputTarget.dispatchEvent(changeEvent)
  }
}
