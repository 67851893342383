import { Controller } from 'stimulus'
import I18n from 'i18n-js'

export default class extends Controller {
  static targets = ['modal', 'greetingSection', 'optionsSection', 'chatSection', 'inputMessage']

  connect() {
    if (!this.hasChatSectionTarget) return
    if (!localStorage.getItem('felicia_chat_status')) localStorage.setItem('felicia_chat_status', 'close')
    if (!localStorage.getItem('felicia_conversation')) localStorage.setItem('felicia_conversation', JSON.stringify([]))
    const conversation = JSON.parse(localStorage.getItem('felicia_conversation'))
    if (conversation.length > 0) {
      const lastMessage = conversation[conversation.length - 1]
      if (Date.now() - (lastMessage.timestamp || 0) > 900000) {
        this.resetChat()
      }
    }

    this.feliciaFaceUrl = this.data.get('feliciaFaceUrl')
    this.boundUpdateModal = this.updateModal.bind(this)
    window.addEventListener('felicia:toggle', this.boundUpdateModal)
    this.updateModal()
    this.loadConversation()
    if (!this.hasInputMessageTarget) return;
    this.inputMessageTarget.addEventListener('input', () => this.toggleSendIcon());
    this.toggleSendIcon();
  }

  toggleSendIcon() {
    const sendButton = this.inputMessageTarget.nextElementSibling;
    if (!sendButton) return;
    sendButton.classList.toggle('active', this.inputMessageTarget.value.trim() !== '');
  }

  disconnect() {
    window.removeEventListener('felicia:toggle', this.boundUpdateModal)
  }

  toggleStatus() {
    let currentStatus = localStorage.getItem('felicia_chat_status')
    let newStatus = currentStatus === 'open' ? 'close' : 'open'
    localStorage.setItem('felicia_chat_status', newStatus)
    window.dispatchEvent(new CustomEvent('felicia:toggle', { detail: { status: newStatus } }))
    this.updateModal()
  }

  updateModal() {
    if (!this.hasModalTarget) return
    let currentStatus = localStorage.getItem('felicia_chat_status')
    this.modalTarget.style.display = currentStatus === 'open' ? 'block' : 'none'
  }

  loadConversation() {
    const conversation = JSON.parse(localStorage.getItem('felicia_conversation'))
    if (conversation.length > 0) {
      if (this.hasGreetingSectionTarget) this.greetingSectionTarget.style.display = 'none'
      if (this.hasOptionsSectionTarget) this.optionsSectionTarget.style.display = 'none'
      this.chatSectionTarget.style.display = 'flex'
      this.renderConversation(conversation)
    }
  }

  renderConversation(messages) {
    this.chatSectionTarget.innerHTML = ''
    messages.forEach(msg => {
      const divMsg = document.createElement('div')
      divMsg.classList.add('message', msg.role)

      let avatar
      if (msg.role === 'user') {
        let user = msg.userName || I18n.t('felicia.default_user')
        avatar = document.createElement('div')
        avatar.classList.add('message-avatar', 'user-avatar')
        avatar.textContent = user[0].toUpperCase()
      } else {
        avatar = document.createElement('img')
        avatar.classList.add('message-avatar')
        avatar.src = this.feliciaFaceUrl
        avatar.alt = 'Felicia'
      }

      const nameLabel = document.createElement('div')
      nameLabel.classList.add('message-name')
      nameLabel.textContent = msg.role === 'user' ? (msg.userName || I18n.t('felicia.default_user')) : 'Felicia'

      const messageContainer = document.createElement('div')
      messageContainer.classList.add('message-container')

      const div = document.createElement('div')
      div.innerHTML = msg.text
      messageContainer.appendChild(nameLabel)
      messageContainer.appendChild(div)

      const timeLabel = document.createElement('div')
      timeLabel.classList.add('message-time')
      if (msg.timestamp) {
        timeLabel.textContent = new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      }
      messageContainer.appendChild(timeLabel)

      divMsg.appendChild(avatar)
      divMsg.appendChild(messageContainer)

      this.chatSectionTarget.appendChild(divMsg)
    })
    this.chatSectionTarget.scrollTop = this.chatSectionTarget.scrollHeight
  }

  selectQuestion(event) {
    const question = event.currentTarget.dataset.question
    if (this.hasInputMessageTarget) this.inputMessageTarget.disabled = true
    this.sendUserMessage(question)
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.sendMessage()
    }
  }

  sendMessage() {
    if (!this.hasInputMessageTarget) return
    const messageValue = this.inputMessageTarget.value.trim()
    if (messageValue === '') return
    this.inputMessageTarget.disabled = true
    this.sendUserMessage(messageValue)
  }

  sendUserMessage(text) {
    const userName = this.chatSectionTarget.dataset.userName || I18n.t('felicia.default_user')
    const conversation = JSON.parse(localStorage.getItem('felicia_conversation')) || []

    conversation.push({
      role: 'user',
      text: text,
      messageType: 'text',
      userName: userName,
      timestamp: Date.now()
    })
    localStorage.setItem('felicia_conversation', JSON.stringify(conversation))
    if (this.hasInputMessageTarget) this.inputMessageTarget.value = ''

    if (conversation.length === 1) {
      if (this.hasGreetingSectionTarget) this.greetingSectionTarget.style.display = 'none'
      if (this.hasOptionsSectionTarget) this.optionsSectionTarget.style.display = 'none'
      this.chatSectionTarget.style.display = 'flex'
    }

    this.renderConversation(conversation)
    this.sendToBackend(conversation)
  }

  sendToBackend(conversation) {
    this.showThinking()
    fetch('/felicia/chat', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.getCSRFToken()
      },
      body: JSON.stringify({ conversation: conversation })
    })
      .then(response => response.text())
      .then(html => {
        this.hideThinking()
        let conv = JSON.parse(localStorage.getItem('felicia_conversation')) || []
        const botMessage = {
          role: 'bot',
          text: html || '',
          messageType: 'text',
          timestamp: Date.now()
        }
        conv.push(botMessage)
        localStorage.setItem('felicia_conversation', JSON.stringify(conv))
        this.renderConversation(conv)

        if (this.hasInputMessageTarget) {
          this.inputMessageTarget.disabled = false
          this.inputMessageTarget.focus()
        }
      })
      .catch(error => {
        this.hideThinking()
        let conv = JSON.parse(localStorage.getItem('felicia_conversation')) || []
        conv.push({
          role: 'bot',
          text: I18n.t('felicia.server_error'),
          messageType: 'text',
          timestamp: Date.now()
        })
        localStorage.setItem('felicia_conversation', JSON.stringify(conv))
        this.renderConversation(conv)

        if (this.hasInputMessageTarget) {
          this.inputMessageTarget.disabled = false
          this.inputMessageTarget.focus()
        }
      })
  }

  showThinking() {
    if (!this.chatSectionTarget) return
    this.thinkingElement = document.createElement('div')
    this.thinkingElement.classList.add('message', 'bot')
    
    const avatar = document.createElement('img')
    avatar.classList.add('message-avatar')
    avatar.src = this.feliciaFaceUrl
    avatar.alt = 'Felicia'
    
    const messageContainer = document.createElement('div')
    messageContainer.classList.add('message-container')
    
    const nameLabel = document.createElement('div')
    nameLabel.classList.add('message-name')
    nameLabel.textContent = I18n.t('felicia.title')
    
    const textDiv = document.createElement('div')
    textDiv.innerHTML = I18n.t('felicia.thinking')
    
    messageContainer.appendChild(nameLabel)
    messageContainer.appendChild(textDiv)
    
    this.thinkingElement.appendChild(avatar)
    this.thinkingElement.appendChild(messageContainer)
    
    this.chatSectionTarget.appendChild(this.thinkingElement)
    this.chatSectionTarget.scrollTop = this.chatSectionTarget.scrollHeight
  }

  hideThinking() {
    if (this.thinkingElement && this.chatSectionTarget.contains(this.thinkingElement)) {
      this.chatSectionTarget.removeChild(this.thinkingElement)
      this.thinkingElement = null
    }
  }

  getCSRFToken() {
    const meta = document.querySelector('meta[name="csrf-token"]')
    return meta ? meta.getAttribute('content') : ''
  }

  resetChat() {
    localStorage.setItem('felicia_conversation', JSON.stringify([]))
    if (this.hasGreetingSectionTarget) this.greetingSectionTarget.style.display = 'block'
    if (this.hasOptionsSectionTarget) this.optionsSectionTarget.style.display = 'block'
    this.chatSectionTarget.style.display = 'none'
    this.chatSectionTarget.innerHTML = ''
  }
}
